import React, { memo } from "react"
import styled from "styled-components"
import checkLight from "../../images/tick-light.png"
import checkDark from "../../images/tick-dark.png"
import checkEmpty from "../../images/tick-empty.png"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Checkbox = memo(
  ({ label, id, onClick, slug, checked, checkable = true, className = "" }) => {
    const [active, setActive] = React.useState(false)
    const handleClick = () => {
      const emitClick = {
        target: { name: slug, checked: checkable ? !active : checked },
      }
      onClick(emitClick)
      checkable && setActive(checkable ? !active : checked)
    }

    return (
      <Container
        data-id={id}
        active={checked}
        onClick={handleClick}
        className={className}
      >
        <img
          src={checked ? checkLight : checkEmpty}
          alt="check icon"
          data-id={id}
        />
        {label}
      </Container>
    )
  }
)
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  white-space: pre;
  padding-bottom: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  break-inside: avoid;
  transition: 0.2s all ease-in-out;
  &:hover {
    margin-right: 15px;
    img {
      margin-right: 15px;
    }
  }
  > img {
    break-inside: avoid;
    padding: 5px;
    transition: 0.2s all ease-in-out;
    background: ${({ active }) => (active ? "#ED1C24" : "white")};
    width: 25px;
    height: 25px;
    border-radius: 4px;
    margin-right: 10px;
    transition: 0.2s all ease-in-out;
  }
`

export default Checkbox
