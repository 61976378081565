import React from "react"
import styled from "styled-components"
import Inner from "../../layout/pageInner/Inner"
import Breadcrumbs from "../../atoms/breadcrumb"

const Heading = ({ location }) => {
  const { pathname } = location

  const title = React.useMemo(() => {
    if (pathname) {
      const noSlash = pathname.split("/").filter((x) => x !== "")
      const lastElement = noSlash[noSlash.length - 1]
      return lastElement
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    }
  }, [pathname])

  return (
    <Container>
      <Inner>
        <Flex>
          <Breadcrumbs pathname={pathname} />
          <Title>{title}</Title>
        </Flex>
      </Inner>
    </Container>
  )
}

export default Heading

const Container = styled.div``

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: center;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.midnight["060"]};
  font-family: Roboto;
  font-size: 48px;
  font-weight: 300;
  margin: 0;
`
