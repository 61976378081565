import React, { memo } from "react"
import styled from "styled-components"
import ShopHeading from "../../zzz/organisms/heading/ShopHeading"
import TextRow from "./TextRow"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const ShopPageLayout = memo(
  ({ children, location, currentCategory, selected }) => {
    if (currentCategory != undefined) {
      const pathname = location.pathname
      const categories = pathname
        .substring(1, pathname.length - 1)
        .split("/")
        .toString()
        .replace(/,/g, ", ")
        .replace(/-/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ")
      const category = currentCategory.name
    }
    return (
      <Container>
        <ShopHeading location={location} />
        {children}
      </Container>
    )
  }
)
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div``

export default ShopPageLayout
