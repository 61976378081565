import React, { useState, useEffect, useContext } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import ProductList from "./ProductList"
import styled from "styled-components"
import {
  useQueryParam,
  NumberParam,
  encodeDelimitedArray,
  decodeDelimitedArray,
} from "use-query-params"
import { Responsive, windowSizes } from "../../utils/responsive"
import queryString from "query-string"
import { slugify } from "../../lib/stringHandling"
import FilterItems from "./FilterItems"
import Inner from "../../zzz/layout/pageInner/Inner"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import Search from "../../zzz/atoms/icons/files/search.jsx"
import Size from "../../zzz/atoms/icons/files/size.jsx"
import Bed from "../../zzz/atoms/icons/files/bed.jsx"
import MoonStar from "../../zzz/atoms/icons/files/moon-star.jsx"
import StarSolid from "../../zzz/atoms/icons/files/star-solid.jsx"
import ChevronDown from "../../zzz/atoms/icons/files/chevron-down.jsx"
import BlurLoader from "../../zzz/organisms/blurLoader"

// These filter options are constants
export const sizesToChooseFrom = [
  "single",
  "single-xl",
  "three-quarter",
  "three-quarter-xl",
  "double",
  "double-xl",
  "queen",
  "queen-xl",
  "king",
  "king-xl",
  "super-king",
]
export const comfortLevelsToChooseFrom = ["plush", "medium", "firm"]
export const sortMethodsToChooseFrom = [
  "shipping-fastest",
  "price-low-to-high",
  "price-high-to-low",
]

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const ProductsFiltered = ({
  selected = {},
  currentCategory,
  prevState,
  location,
}) => {
  // Fetch the GraphQL query
  const data = useStaticQuery(GRAPHQL)
  const allProducts = data.allWcProducts.nodes

  const [typeFilter, setTypeFilter] = useState(
    (selected.type && selected.type.map(slugify)) || []
  )
  const [sizesFilter, setSizesFilter] = useState(
    (selected.size && selected.size.map(slugify)) || []
  )
  const [brandFilter, setBrandFilter] = useState(
    (selected.brand && selected.brand.map(slugify)) || []
  )
  const [comfortLevelFilter, setComfortLevelFilter] = useState([])
  const [sortState, setSortState] = useState([sortMethodsToChooseFrom[0]])
  const [productList, setProductList] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [navigatedUrlParsed, setNavigatedUrlParsed] = useState(false)

  let priceLimit = 0
  if (selected.priceLimit) {
    priceLimit = selected.priceLimit
  }

  useEffect(() => {
    setLoading(false)
  }, [productList])

  // Run this function to filter the products every time a filter changes
  const filterProducts = () => {
    // For each Product, check if a filter matches it
    const filteredProds = allProducts.filter((pr) => {
      // If the product has no categories specified, do not show it
      if (!pr.categories) {
        return false
      }

      if (
        priceLimit !== 0 &&
        pr.bundled_items.length === 0 &&
        pr.product_variations.length > 0
      ) {
        // variable products
        let filtered_product_variations = []
        pr.product_variations.forEach((variation) => {
          if (variation.price <= priceLimit) {
            filtered_product_variations.push(variation)
          }
        })
        pr.product_variations = filtered_product_variations
        if (pr.product_variations.length === 0) {
          //Dont show if there are now no more variations for this product due to limit
          return false
        }
      }

      if (
        priceLimit !== 0 &&
        pr.product_variations.length === 0 &&
        pr.bundled_items.length > 0
      ) {
        // bundle products

        if (
          pr.bundled_items[0].length === 0 &&
          !pr.bundled_items[1].length === 0
        ) {
          return false
        }

        let new_a_bundled_items = []
        let new_b_bundled_items = []

        pr.bundled_items[0].product_variations.forEach((item_a) => {
          const a_price = item_a.price
          const a_size = item_a.size[0]
          pr.bundled_items[1].product_variations.forEach((item_b) => {
            const b_price = item_b.price
            const b_size = item_b.size[0]
            if (a_size === b_size) {
              const ab_bundle_price = a_price + b_price
              if (ab_bundle_price <= priceLimit) {
                new_a_bundled_items.push(item_a)
                new_b_bundled_items.push(item_b)
              }
            }
          })
        })

        pr.bundled_items[0].product_variations = new_a_bundled_items
        pr.bundled_items[1].product_variations = new_b_bundled_items

        if (
          pr.bundled_items[0].product_variations.length === 0 ||
          pr.bundled_items[1].product_variations.length === 0
        ) {
          return false
        }
      }

      if (
        priceLimit !== 0 &&
        pr.product_variations.length === 0 &&
        pr.bundled_items.length === 0 &&
        pr.price > priceLimit
      ) {
        // simple products
        return false
      }

      // Set the main page filter, otherwise show everything
      const mainCheck = pr.categories.some((productCat) => {
        return currentCategory ? currentCategory.slug === productCat.slug : true
      })

      // Set the type filter, otherwise show everything
      const typeCheck = (() => {
        if (typeFilter.length > 0) {
          return typeFilter.some((checkCat) => {
            const checkCatArr =
              checkCat === "wooden-bunk-beds"
                ? ["wooden-beds", "bunk-beds"]
                : [checkCat]
            return checkCatArr.every((woodenBunkBedSubType) => {
              return pr.categories.some((productCat) => {
                return slugify(productCat.slug) === woodenBunkBedSubType
              })
            })
          })
        }
        return true
      })()

      // Set the brand filter, otherwise show everything
      const brandCheck = pr.categories.some((productCat) => {
        if (brandFilter.length > 0) {
          return brandFilter.some(
            (checkCat) => slugify(productCat.slug) === checkCat
          )
        }
        return true
      })

      // Set the comfort level filter, otherwise show everything
      let comfortLevelCheck = true
      if (comfortLevelFilter.length > 0) {
        comfortLevelCheck =
          pr.attributes.length === 0
            ? true
            : pr.attributes.some((productAtt) => {
                return comfortLevelFilter.some((checkOpt) =>
                  productAtt.options.some((opt) => slugify(opt) === checkOpt)
                )
              })
      }

      // AND all the checks together to filter if the product should show
      if (mainCheck && typeCheck && brandCheck && comfortLevelCheck) {
        return pr
      }
    })
    setProductList(filteredProds)
  }

  // If any of the filter selections change, run the filter again and update the URL string
  useEffect(() => {
    filterProducts()
    if (
      typeof HTMLIFrameElement != "undefined" &&
      window.location &&
      window.location.pathname &&
      history
    ) {
      // if the user just navigated to this URL, then build the filter from the URL
      if (!navigatedUrlParsed && window.location.search) {
        const filterBlockArray = queryString.parse(window.location.search)

        if (filterBlockArray) {
          if (filterBlockArray.type) {
            const typesActive = filterBlockArray.type.split(",")
            setTypeFilter(typesActive)
          }
          // backwards compatibility for when the query was still size
          // this will convert the size query to sizes
          if (filterBlockArray.sizes || filterBlockArray.size) {
            const sizesActive = filterBlockArray?.sizes?.split(",") ?? []
            sizesActive.push(...(filterBlockArray?.size?.split(",") ?? []))
            setSizesFilter(sizesActive)
          }
          if (filterBlockArray.brand) {
            const brandsActive = filterBlockArray.brand.split(",")
            setBrandFilter(brandsActive)
          }
          if (filterBlockArray.comf) {
            const comfActive = filterBlockArray.comf.split(",")
            setComfortLevelFilter(comfActive)
          }
          if (filterBlockArray.sort) {
            const sortActive = filterBlockArray.sort.split(",")
            setSortState(sortActive)
          }
        }
      } else {
        // if the filter changes, update the URL for sharing/remembering
        let pathname = window.location.pathname
        const oldSearch = window.location.search
        const pathQueries = queryString.parse(window.location.search)
        if (
          typeFilter &&
          typeFilter.length &&
          typeFilter.join(",") !== pathQueries["type"]
        ) {
          pathQueries["type"] = typeFilter.join(",")
        }
        if (sizesFilter && sizesFilter.length) {
          pathQueries["sizes"] = sizesFilter.join(",")
        }
        if (
          brandFilter &&
          brandFilter.length &&
          brandFilter.join(",") !== pathQueries["brand"]
        ) {
          pathQueries["brand"] = brandFilter.join(",")
        }
        if (
          comfortLevelFilter &&
          comfortLevelFilter.length &&
          comfortLevelFilter.join(",") !== pathQueries["comf"]
        ) {
          pathQueries["comf"] = comfortLevelFilter.join(",")
        }
        if (
          sortState &&
          sortState.length &&
          sortState.join(",") !== pathQueries["sort"]
        ) {
          pathQueries["sort"] = sortState.join(",")
        }

        const stringifiedQueries = queryString.stringify(pathQueries)
        let newPath = pathname
        if (
          Object.values(pathQueries).length &&
          stringifiedQueries !== oldSearch
        ) {
          newPath = `${pathname}?${stringifiedQueries}`
        }
        history.replaceState(null, "", newPath)
      }
    }
    setNavigatedUrlParsed(true)
  }, [typeFilter, sizesFilter, brandFilter, comfortLevelFilter, sortState])

  const reset = () =>
    [
      setTypeFilter,
      setSizesFilter,
      setBrandFilter,
      setComfortLevelFilter,
      setSortState,
    ].forEach((update) => update([]))

  const filters = {
    type: typeFilter,
    size: sizesFilter,
    brand: brandFilter,
    comfortLevel: comfortLevelFilter,
    sort: sortState,
  }

  return (
    <>
      <FilterRow id="filter-row-99">
        <Responsive maxWidth={windowSizes.tablet}>
          <MobileTitle open={open} onClick={() => setOpen((v) => !v)}>
            <div className="flex" open={open}>
              <SvgIcon
                SvgComponent={Search}
                className="mr"
                hue="ruby"
                shade="060"
                size="md"
              />
              Filters
            </div>
            <div className="flex">
              <SvgIcon
                className="chev ml"
                SvgComponent={ChevronDown}
                hue="white"
                shade="000"
                size="md"
                open={open}
              />
            </div>
          </MobileTitle>
          <MobileCollapse expandFilters={open}>
            <FilterItems
              selected={selected}
              filters={filters}
              data={data}
              setTypeFilter={setTypeFilter}
              setSizesFilter={setSizesFilter}
              setBrandFilter={setBrandFilter}
              setComfortLevelFilter={setComfortLevelFilter}
              setSortState={setSortState}
            />
          </MobileCollapse>
        </Responsive>
        <Responsive minWidth={windowSizes.tablet}>
          <Collapse>
            <FilterItems
              selected={selected}
              filters={filters}
              data={data}
              setTypeFilter={setTypeFilter}
              setSizesFilter={setSizesFilter}
              setBrandFilter={setBrandFilter}
              setComfortLevelFilter={setComfortLevelFilter}
              setSortState={setSortState}
            />
          </Collapse>
        </Responsive>
      </FilterRow>
      {[...typeFilter, ...sizesFilter, ...brandFilter, ...comfortLevelFilter]
        .length !== 0 && (
        <Summary>
          <Inner>
            <Block>
              <div>
                {[
                  [typeFilter, Bed],
                  [sizesFilter, Size],
                  [brandFilter, StarSolid],
                  [comfortLevelFilter, MoonStar],
                ].map(renderTags)}
              </div>
              <Button
                onClick={() => {
                  location.pathname === "/shop/"
                    ? reset()
                    : navigate("/shop/", { state: { shopScroll: true } })
                }}
              >
                Clear Filters
              </Button>
            </Block>
          </Inner>
        </Summary>
      )}
      <BlurLoader loading={loading}>
        <Items>
          <ProductList
            // DEBUG: TEMPORARILY DISABLE UNTIL JELLYFISH COMPLETES TESTING
            // usePagination
            productList={productList}
            sizesFilter={sizesFilter}
            sort={sortState}
            loading={loading}
          />
        </Items>
      </BlurLoader>
    </>
  )
}
export default ProductsFiltered

const renderTag = (icon) => (label, index) =>
  (
    <Tag key={index}>
      <SvgIcon SvgComponent={icon} />
      {label == "Uncategorised" ? "Other" : label}
    </Tag>
  )

// // Use this  code when we port all filters over to the query param lib
// const [
//   typeFilter = (selected.size && selected.size.map(slugify)) || [],
//   setTypeFilter,
// ] = useQueryParam("type", CommaArrayParam)
/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
const CommaArrayParam = {
  encode: (array) => encodeDelimitedArray(array, ","),
  decode: (arrayStr) => decodeDelimitedArray(arrayStr, ","),
}

const Tag = styled.div`
  background: #ececec;
  color: #5d5d5d;
  border-radius: 3px;
  margin: 0 4px;
  padding: 1px 11px;
  font-size: 13px;
  margin: 3px;
  display: flex;
  align-items: center;
  span.icon {
    margin-right: 10px;
  }
`

const formatName = (str) =>
  str
    .split("-")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ")

const SummaryItem = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  span.label {
    font-weight: 500;
    margin-right: 10px;
  }
`

const Summary = styled.div`
  background: #fff;
  padding: 20px;
  box-shadow: 0 1px 3px 2px #22324729;
`

const Block = styled.div`
  display: grid;
  grid-template-columns: auto 140px;
  grid-gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    h6 {
      text-transform: unset;
    }
  }
`

const MobileTitle = styled.div`
  padding: 20px 0;
  display: none;
  font-size: 20px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 18px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 15px 0;
  }
  .flex {
    display: flex;
    align-items: center;
    > .icon.mr {
      margin-right: 15px;
    }
    > .icon.ml {
      margin-left: 15px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .icon.chev {
      transition: 0.2s all ease-in-out;
      ${({ open }) => open && "transform: rotate(180deg);"}
    }
  }
`

const MobileCollapse = styled.div`
  max-height: ${({ expandFilters }) => (expandFilters ? "80vh" : "0")};
  height: 100%;
  transition: max-height 0.5s ease-in-out;
  background: ${({ theme }) => theme.colors.midnight["060"]};
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    overflow: hidden;
    .inner {
      padding: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    overflow-y: scroll;
  }
`
const Collapse = styled.div`
  height: auto;
  background: ${({ theme }) => theme.colors.midnight["060"]};
`

const Button = styled.button`
  align-self: center;
  display: block;
  background: #77848e;
  color: white;
  padding: 10px 15px 10px 5px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
  &:disabled {
    transform: translateY(0px);
    background: #d6d6d6;
    color: #6f6f6f;
    cursor: not-allowed;
  }
  .icon {
    padding-right: 5px;
  }
`

const Items = styled.div`
  padding: 60px 0;
  background: #f6f6f6;
  padding-top: 20px;
`

const FilterRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.midnight["020"]};
  background: ${({ theme }) => theme.colors.midnight["060"]};
  color: white;
  position: sticky;
  top: 0;
  z-index: 5;
  @media (max-width: 1024px) {
    > .inner {
      padding: 0 20px;
    }
  }
  @media (max-width: 1023px) {
    top: 70px;
  }
`

// Ender each filter
const renderTags = ([filterList, tagName]) =>
  filterList.map(formatName).map(renderTag(tagName))

const GRAPHQL = graphql`
  {
    rootCategories: allWcProductsCategories(
      filter: { wordpress_parent_id: { eq: 0 } }
    ) {
      nodes {
        name
        slug
      }
    }
    brandCategories: allWcProductsCategories(
      filter: { wordpress_parent: { slug: { eq: "all-brands" } } }
    ) {
      nodes {
        name
        slug
      }
    }
    ## ProductFilter: All products
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
      }
    ) {
      nodes {
        ...ProductCardInfo
      }
    }
  }
`
