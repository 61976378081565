import React, { memo, useState } from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronDown from "../../zzz/atoms/icons/files/chevron-down.jsx"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import Checkbox from "./Checkbox"
import { path } from "../../lib/util"
import { checkWindowSize } from "../../utils/responsive"
import cross from "../../images/icons/quit-cross.svg"
import {
  slugify,
  titleCase,
  replaceAll,
  replace,
} from "../../lib/stringHandling"
import queryString from "query-string"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const FilterItem = memo(
  ({
    label,
    filterKey,
    items = [],
    filterChange,
    filter,
    disabled,
    icon,
    filterValues,
    selectMultiple = true,
  }) => {
    const [open, setOpen] = useState(false)

    const handleExit = ({ relatedTarget }) => {
      const targetId = path(["dataset", "id"], relatedTarget)
      if (targetId !== filterKey) setOpen(false)
    }

    const isMobile = checkWindowSize({ maxWidth: 1145, defaultRender: true })

    // Mouse over should only be enabled when on desktop
    const mouseOut = !isMobile ? { onMouseOut: handleExit } : {}
    const mouseOver = !isMobile ? { onMouseOver: () => setOpen(true) } : {}

    // Onclick should only be enabled on mobile
    const mobileClickProps = isMobile
      ? { onClick: () => setOpen((v) => !v) }
      : {} // mobileProps()

    // Build a query string without this filter if it is disabled
    let navigateQueryString = ""
    if (typeof window != "undefined" && disabled) {
      const parsedQuery = queryString.parse(location.search)
      parsedQuery[slugify(filterKey)] = undefined
      navigateQueryString = queryString.stringify(parsedQuery)
    }

    return (
      <Container data-id={filterKey}>
        <Main
          data-id={filterKey}
          {...mouseOut}
          {...mouseOver}
          {...mobileClickProps}
          open={open}
          className={label != "Sort" && "notLastColumn"}
        >
          <div>
            <SvgIcon SvgComponent={icon} hue="ruby" shade="060" size="md" />
            {label}
          </div>
          <div>
            <Circle active={filterValues.length > 0 || disabled}>
              {disabled ? "1" : filterValues.length}
            </Circle>
            <SvgIcon
              className="chev"
              SvgComponent={ChevronDown}
              hue="white"
              shade="000"
              size={isMobile ? "md" : "lg"}
            />
          </div>
        </Main>
        <Dropdown dropdownOpen={open} data-id={filterKey} {...mouseOut}>
          <Wrap data-id={filterKey} length={items.length} disabled={disabled}>
            {disabled ? (
              <DisabledWrap>
                <Back
                  data-id={filterKey}
                  key={filterKey}
                  to={`/shop/?${
                    typeof window != "undefined" ? navigateQueryString : ""
                  }`}
                  state={{
                    shopScroll: true,
                  }}
                >
                  <img data-id={filterKey} src={cross} />
                  {titleCase(replaceAll(disabled[0], "-", " ")).replace(
                    "Xl",
                    "XL"
                  )}
                </Back>
              </DisabledWrap>
            ) : (
              items
                .filter(
                  (item) =>
                    "auto-imported-products" != slugify(item.name || item)
                )
                .map((item, i) => {
                  const name = item.name || item
                  let checkboxLabel =
                    slugify(name) == "uncategorised" ? "other" : name
                  checkboxLabel = titleCase(
                    replaceAll(checkboxLabel, "-", " ")
                  ).replace("Xl", "XL")

                  if (slugify(label) == "sort") {
                    checkboxLabel = replace(checkboxLabel, " ", ": ")
                  }
                  return (
                    <Checkbox
                      dropdownOpen={open}
                      label={checkboxLabel}
                      id={filterKey}
                      key={i}
                      slug={item.slug || name}
                      checked={filterValues.some(
                        (v) => slugify(v) === slugify(name)
                      )}
                      onClick={(e) => filterChange(e, filter)}
                      checkable={selectMultiple}
                    />
                  )
                })
            )}
          </Wrap>
        </Dropdown>
      </Container>
    )
  }
)

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Back = styled(Link)`
  display: block;
  border-radius: 3px;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  color: white;
  align-items: center;
  img {
    margin-right: 15px;
    width: 25px;
    height: 25px;
  }
`

const DisabledWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  > div {
    padding-bottom: 10px;
  }
`

const Circle = styled.div`
  width: 30px;
  height: 30px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 22px;
    height: 22px;
  }
  color: ${({ active }) => (active ? "#fff" : "#8494ab")};
  background: ${({ active }) => (active ? "#ED1C24" : "#3b495d")};
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrap = styled.div`
  padding: 20px;
  columns: ${({ length, disabled }) => (length < 5 || disabled ? 1 : 2)};
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    columns: 1;
  }
`
const Container = styled.div`
  position: relative;
`

const Main = styled.div`
  cursor: pointer;
  &.notLastColumn {
    border-right: 1px solid ${({ theme }) => theme.colors.midnight["080"]};
  }
  padding: 20px 2px;
  background: ${({ open }) => open && "#17212f"};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  @media (max-width: 1200px) {
    font-size: 18px;
    padding: 20px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 5px 10px;
    font-size: 16px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    margin-top: 4px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.midnight["060"]};
    border-right: none;
    padding: 12px 10px;
  }
  .chev {
    transition: 0.2s all ease-in-out;
    transform: ${({ open }) => open && "rotate(180deg)"};
  }
  > div {
    display: flex;
    align-items: center;
    > * {
      margin: 0 10px;
    }
  }
`

const Dropdown = styled.nav`
  max-height: ${({ dropdownOpen }) => (dropdownOpen ? "100vh" : "0")};
  position: absolute;
  top: 85;
  color: white;
  background: #17212f;
  z-index: 2;
  overflow: hidden;
  min-width: 100%;
  height: auto;
  transition: all 0.3s ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    position: relative;
  }
  animation: ${({ dropdownOpen }) =>
    dropdownOpen ? "growDown 400ms ease-in-out;" : "growUp 400ms ease-in-out;"};
  transform-origin: top center;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  @keyframes growUp {
    0% {
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      opacity: 0;
    }
  }
`

export default FilterItem
