import React, { memo } from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const TextRow = memo(({ heading, body }) => {
  let textBlock = body
  if (!textBlock) {
    textBlock = (
      <>
        Our beds come in many different styles and sizes to suit your unique
        sleeping needs and your bedroom layout. If you need some help deciding
        what bed size is perfect for you, take a look at our
        <Link to="/bed-sizes/"> bed size guide.</Link>
      </>
    )
  }
  return (
    <Container>
      <Inner>
        {heading && <h2>{heading}</h2>}
        <p>{textBlock}</p>
      </Inner>
    </Container>
  )
})
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;
  a {
    color: ${({ theme, color }) => (color ? color : theme.colors.ocean["060"])};
  }
  h2 {
    text-transform: capitalize;
    font-size: 36px;
    margin: 0 0 20px 0;
  }
  p {
    margin: 0 0 20px 0;
    &.bold {
      font-weight: bold;
    }
  }
`

export default TextRow
