import React from "react"

const IconRand = ({ fill }) => (
  <svg width="9" height="11" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.02 6.6v4.24H0V0h3.3c.75 0 1.38.08 1.9.23.54.15.97.36 1.3.64.34.28.6.6.75.99.16.38.23.8.23 1.26a3.27 3.27 0 01-.62 1.92c-.2.27-.45.5-.75.7-.3.2-.64.36-1.02.48a2 2 0 01.66.62l2.72 4H6.65a.89.89 0 01-.44-.1.91.91 0 01-.32-.3L3.62 6.97a.8.8 0 00-.29-.28.99.99 0 00-.45-.08h-.86zm0-1.44h1.26c.38 0 .7-.05 1-.14.27-.1.5-.23.69-.4.18-.16.32-.36.4-.6.1-.22.14-.47.14-.75 0-.55-.18-.97-.54-1.27-.36-.3-.92-.44-1.66-.44H2v3.6z"
      fill={fill}
    />
  </svg>
)

export default IconRand
